import users from "./users/phase0_users";
import tasks from "./tasks/phase0_tasks";
import expenses from "./expenses/phase0_expenses";
import projects from "./projects/phase0_projects";
import qrCodes from "./qrCodes/phase0_qrCodes";
import alerts from "./alerts/phase0_alerts";

export default {
  modules: {
    users,
    tasks,
    expenses,
    projects,
    qrCodes,
    alerts,
  },
};
