<template>
  <v-container fluid>
    <template v-if="!loading.overall">
      <!-- Content -->
      <v-container fluid class="pa-0 rounded-lg">
        <v-toolbar v-if="payload.filters.step > 1" flat color="transparent">
          <v-btn
            small
            rounded
            :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary}`"
            @click="payload.filters.step--"
          >
            <v-icon>{{ $vuetify.icons.values.back }}</v-icon>
            <span>back</span>
          </v-btn>
          <v-spacer />
          <v-btn
            small
            rounded
            :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary}`"
            @click="traverse('home')"
          >
            <v-icon>mdi-home</v-icon>
            <span>home</span>
          </v-btn>
        </v-toolbar>
        <template v-if="payload.filters.step === 1">
          <span>Select the module below to see a list of related guides</span>
        </template>
        <!-- Modules -->
        <template v-if="payload.filters.step === 1">
          <v-list class="py-0">
            <v-list-item-group>
              <template v-for="(phase, index) in getPhases">
                <v-list-item three-line @click="traverse('phase', phase)">
                  <v-list-item-icon class="mr-0">
                    <v-icon large class="mr-1">{{ phase.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="text-wrap font-weight-medium text-capitalize"
                        >{{ phase.text }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{ phase.desc }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{ $vuetify.icons.values.next }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </template>
        <!-- Guides -->
        <template v-if="payload.filters.step === 2">
          <!-- Guide repo -->
          <template v-if="guides.length > 0">
            <v-list class="py-0">
              <v-list-item-group>
                <template v-for="(guide, index) in guides">
                  <v-list-item three-line @click="traverse('guide', guide)">
                    <v-list-item-icon class="mr-0">
                      <v-icon class="mr-1" :color="guide.color">{{
                        guide.icon
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="text-wrap font-weight-medium"
                          >{{ guide.text }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span>{{ guide.desc }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>{{ $vuetify.icons.values.next }}</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </template>
          <!-- No Guides -->
          <template v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center">
                  <span class="text--disabled">No guides for this module.</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
        <!-- Instructions -->
        <template v-if="payload.filters.step === 3">
          <span>{{ selectedGuide }}</span>
          <v-list class="py-0">
            <v-list-item-group>
              <template v-for="(instruction, index) in instructions">
                <v-list-item>
                  <v-list-item-action>
                    <span>#{{ index + 1 }}</span>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="text-wrap" v-html="instruction.text"> </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="instruction.action">
                    <v-btn
                      small
                      rounded
                      :style="`background: ${instruction.action.color}; color: ${$vuetify.theme.themes.dark.secondary};`"
                      @click="traverse('instruction-view', instruction)"
                      :disabled="instruction.action.disabled"
                    >
                      <v-icon
                        v-if="instruction.action.iconPosition === 'left'"
                        >{{ instruction.action.icon }}</v-icon
                      >
                      <span :class="`${instruction.action.margin}`">{{
                        instruction.action.label
                      }}</span>
                      <v-icon
                        v-if="instruction.action.iconPosition === 'right'"
                        >{{ instruction.action.icon }}</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </template>
      </v-container>
    </template>
    <template v-else>
      <v-skeleton-loader type="actions, divider, card, table" />
    </template>
  </v-container>
</template>

<script>
// Libraries
import { mapGetters } from "vuex";
// Components

export default {
  name: "helpdesk-view",
  data: () => ({
    loading: {
      overall: false,
    },
    response: {
      guides: [
        // Admin
        {
          text: "What do the colors mean in mytrace?",
          desc: "Based on the status of a view, the colors in mytrace will change (cannot submit = blue, ready to submit = black).",
          value: "systemColors",
          icon: "mdi-magnify",
          color: "black",
          phase: 0,
        },
        // Sourcing
        {
          text: "Add a retail brand",
          desc: "Add a new brand",
          value: "addRetailBrand",
          icon: "mdi-store",
          color: "black",
          phase: 1,
        },
        {
          text: "Add a product category",
          desc: "Add a product category to a brand",
          value: "addProductCategory",
          icon: "mdi-sitemap",
          color: "black",
          phase: 1,
        },
        {
          text: "Add a products to a product category",
          desc: "Add new products to an existing product category",
          value: "addProducts",
          icon: "mdi-package-variant",
          color: "black",
          phase: 1,
        },
        {
          text: "Create a new location",
          desc: "Create a new warehouse location",
          value: "addLocation",
          icon: "mdi-warehouse",
          color: "black",
          phase: 1,
        },
        {
          text: "Create a vendor order",
          desc: "Create a new purchase order for product components",
          value: "addVendorOrder",
          icon: "mdi-truck",
          color: "black",
          phase: 1,
        },
        {
          text: "Update a vendor order",
          desc: "Mark product components as received, add payments/tracking numbers",
          value: "updateVendorOrder",
          icon: "mdi-pencil",
          color: "black",
          phase: 1,
        },
        // Manufacturing
        // Sales
        {
          text: "Add an account",
          desc: "Add a new lead/account to mytrace",
          value: "addSalesAccount",
          icon: "mdi-folder-account",
          color: "black",
          phase: 3,
        },
        {
          text: "Update an account",
          desc: "Modify an account's information (Name/Address/Status/Managed by...)",
          value: "updateSalesAccount",
          icon: "mdi-pencil",
          color: "black",
          phase: 3,
        },
        {
          text: "Add contacts to an account",
          desc: "Add contacts (owner/general manager) to an account",
          value: "addSalesContact",
          icon: "mdi-badge-account-horizontal",
          color: "black",
          phase: 3,
        },
        {
          text: "Add tasks to an account",
          desc: "Add tasks (Calls/Meetings...) to an account",
          value: "addSalesTasks",
          icon: "mdi-calendar-check",
          color: "black",
          phase: 3,
        },
        {
          text: "Create a sales order",
          desc: "Create a new order (sales/sample) for an account",
          value: "newSalesOrder",
          icon: "mdi-clipboard-list",
          color: "black",
          phase: 3,
        },
        {
          text: "Sales Order - Process",
          desc: "How are the sales orders organized in mytrace? What do the statuses mean?",
          value: "salesOrderProcess",
          icon: "mdi-help-circle",
          color: "black",
          phase: 3,
        },
      ],
      instructions: [
        // Admin - mytrace Colors
        {
          text: "mytrace uses different colors to give visual cues on what to do next:",
          sort: 1,
          related: "systemColors",
          phase: 0,
          action: null,
        },
        {
          text: "Blue: Related to a clickable button or an area to focus on.",
          sort: 2,
          related: "systemColors",
          phase: 0,
          action: null,
        },
        {
          text: "Yellow: Used to signify the field is required & needs to be filled out to move forward.",
          sort: 3,
          related: "systemColors",
          phase: 0,
          action: null,
        },
        {
          text: "Orange: Related to updating content within mytrace. If a field has been updated, the background color will turn orange.",
          sort: 4,
          related: "systemColors",
          phase: 0,
          action: null,
        },
        {
          text: "Green/Red: submit or cancel.",
          sort: 5,
          related: "systemColors",
          phase: 0,
          action: null,
        },
        {
          text: "Black/Grey/White: When there are no further actions needed the header of a card will change from blue => black.",
          sort: 6,
          related: "systemColors",
          phase: 0,
          action: null,
        },
        // Sourcing - Retail Brand
        {
          text: "A new brand can be added from Sourcing => Retail Brands.",
          sort: 1,
          related: "addRetailBrand",
          phase: 1,
          action: {
            link: "brands-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click the "+ Retail Brand" button to add a new brand. If this button is disabled, additional brand slots can be purchased.`,
          sort: 2,
          related: "addRetailBrand",
          phase: 1,
          action: null,
        },
        {
          text: `Complete the fields in yellow to submit. The "Available Payment Terms" will determine what payment terms are available when your account's place their own orders on mytrace's retail partner platform.`,
          sort: 3,
          related: "addRetailBrand",
          phase: 1,
          action: null,
        },
        // Sourcing - Product Category
        {
          text: "A new brand can be added from Sourcing => Retail Brand Products.",
          sort: 1,
          related: "addProductCategory",
          phase: 1,
          action: {
            link: "products-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click the "+ Category" button to begin adding a new category. Select the brand this category should be associated with & complete the fields in yellow. All of the required fields to submit are shown in the "Submission Checklist".`,
          sort: 2,
          related: "addProductCategory",
          phase: 1,
          action: null,
        },
        {
          text: `"Components" (individual parts needed for manufacturing: boxes/inserts/stickers...) can be set for this category which can then be placed on a Vendor Order for use with mytrace's Work Order manufacturing feature.`,
          sort: 3,
          related: "addProductCategory",
          phase: 1,
          action: null,
        },
        {
          text: `If this product category uses Raw Materials, this value will be used when calculating the needed material for a Work Order (Each unit is 1 gram of raw material, 1,000 units = 1,000 grams).`,
          sort: 3,
          related: "addProductCategory",
          phase: 1,
          action: null,
        },
        {
          text: `If this product category is available for sale through a Sales Order, select the "For Sale" option. The MSRP/list price & additional parameters can be set within this option.`,
          sort: 4,
          related: "addProductCategory",
          phase: 1,
          action: null,
        },
        {
          text: `The "Visible in Store Events" option will make this category be shown when completing a summary for a Store Event.`,
          sort: 5,
          related: "addProductCategory",
          phase: 1,
          action: null,
        },
        // Sourcing - Products
        {
          text: "New products can be added to an existing product category from Sourcing => Retail Brand Products.",
          sort: 1,
          related: "addProducts",
          phase: 1,
          action: {
            link: "products-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click the "+ Products" button to begin adding a new products. Select the related brand/category these products should be associated with.`,
          sort: 2,
          related: "addProducts",
          phase: 1,
          action: null,
        },
        {
          text: `A list of the existing products in this category will be shown. The details of each of these products can be updated in this view using the orange "Edit" button to the right`,
          sort: 3,
          related: "addProducts",
          phase: 1,
          action: {
            link: "",
            label: "edit",
            icon: "mdi-pencil",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: true,
          },
        },
        {
          text: `New products can be added using the "+" button in the top right of the New Products table. All new products added need to be locked to submit. The header of this table will change from blue => black when no further changes are needed.`,
          sort: 4,
          related: "addProducts",
          phase: 1,
          action: null,
        },
        // Sourcing - Location
        {
          text: "A new warehouse location can be added from Sourcing => Locations.",
          sort: 1,
          related: "addLocation",
          phase: 1,
          action: {
            link: "locations-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click the "+ Location" button to begin add a new location. Complete the fields in yellow to submit.`,
          sort: 2,
          related: "addLocation",
          phase: 1,
          action: {
            link: "locations-new",
            label: "location",
            icon: "mdi-plus",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Locations that are marked as a "Fulfillment" are used for manufactruing & will have access to raw materials/work orders.`,
          sort: 3,
          related: "addLocation",
          phase: 1,
          action: null,
        },
        // Sourcing - Vendor Order, New
        {
          text: "A new vendor order can be created from Sourcing => Vendor Orders.",
          sort: 1,
          related: "addVendorOrder",
          phase: 1,
          action: {
            link: "vendorOrders-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click the "+ Vendor Order" button to begin creating a new purchase order. Select the related brand/categories & delivery location.`,
          sort: 2,
          related: "addVendorOrder",
          phase: 1,
          action: {
            link: "vendorOrder-brand-new",
            label: "order",
            icon: "mdi-plus",
            color: "#2196F3",
            margin: "",
            iconPosition: "left",
            disabled: false,
          },
        },
        {
          text: `Set the QTY for each product in the "QTY" column. If the same QTY of each product is being purchased, the gray "QTY To Spread" field can be used to apply the same QTY at once.`,
          sort: 3,
          related: "addVendorOrder",
          phase: 1,
          action: {
            link: "",
            label: "apply",
            icon: "mdi-arrow-down",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: true,
          },
        },
        {
          text: `The "Edit" button can be used to edit the components being purchased for that SKU. If an individual component is removed, the overall cost will automatically be updated.`,
          sort: 4,
          related: "addVendorOrder",
          phase: 1,
          action: null,
        },
        {
          text: `The "Lock All" button can be used to confirm all of the selected products at once, instead of having to individually lock them one-by-one.`,
          sort: 4,
          related: "addVendorOrder",
          phase: 1,
          action: null,
        },
        {
          text: `Once the final details of this order are set (vendor/order date), this order can be submitted. The components of this order will now be shown at "Incoming" inventory to the delivery location.`,
          sort: 5,
          related: "addVendorOrder",
          phase: 1,
          action: null,
        },
        // Sourcing - Vendor Orders, Update
        {
          text: "Search for your Vendor Order from Sourcing => Vendor Orders.",
          sort: 1,
          related: "updateVendorOrder",
          phase: 1,
          action: {
            link: "vendorOrders-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Set the filters & click "Search". Click the "View" button to see the details of the Vendor Order.`,
          sort: 2,
          related: "updateVendorOrder",
          phase: 1,
          action: null,
        },
        {
          text: `The component invoices (hardware/packaging invoices) shows the delivery & payment status of each invoice. Each section of the invoice is broken into a different panel (Components/Shipments/Payments).`,
          sort: 3,
          related: "updateVendorOrder",
          phase: 1,
          action: null,
        },
        {
          text: `The "Edit" button at the bottom of the 'Components' view will show a dialog where the QTY can be marked as received.`,
          sort: 4,
          related: "updateVendorOrder",
          phase: 1,
          action: null,
        },
        {
          text: `When the component line items are updated, this inventory is immediately available at the delivery location. If the total QTY is set within the "Received" column, only the 'Shipment Complete' button is available as there are no further updates.`,
          sort: 5,
          related: "updateVendorOrder",
          phase: 1,
          action: null,
        },
        {
          text: `Tracking numbers can be added to this order from the "Shipments" panel. Similar to the components, an "Edit" button is available at the bottom of the panel to update the status of this tracking number.`,
          sort: 6,
          related: "updateVendorOrder",
          phase: 1,
          action: null,
        },
        {
          text: `Payments can be added to this Vendor Order from the "Payments" panel. As payments are added to the order, the balance will update in real-time. Multiple payments can be added at once with the "+" at the top right of the payments table.`,
          sort: 7,
          related: "updateVendorOrder",
          phase: 1,
          action: null,
        },
        // Sales - Create new account
        {
          text: "Navigate to Sales => Accounts",
          sort: 1,
          related: "addSalesAccount",
          phase: 3,
          action: {
            link: "accounts-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click the blue "+ Account" button in the top right of the table.`,
          sort: 2,
          related: "addSalesAccount",
          phase: 3,
          action: {
            link: "accounts-new",
            label: "account",
            icon: "mdi-plus",
            margin: "mr-1",
            color: "#2196F3",
            iconPosition: "left",
            disabled: false,
          },
        },
        {
          text: `Search by the account's FULL license number to prevent duplicates. If no duplicate is found, complete all the fields with a yellow background to submit.`,
          sort: 3,
          related: "addSalesAccount",
          phase: 3,
          link: "",
          action: null,
        },
        {
          text: `An account profile is split between the brand (payment terms/staff managing/status) & storefront information (store name/shipping address).`,
          sort: 4,
          related: "addSalesAccount",
          phase: 3,
          link: "",
          action: null,
        },
        {
          text: `A history of all changes to this account can be seen in the "Ledger" at the bottom of the Account profile. Each time the account profile is viewed by a user, this will be shown within the "Last Viewed" tab.`,
          sort: 5,
          related: "addSalesAccount",
          phase: 3,
          link: "",
          action: null,
        },
        // Sales - Update account
        {
          text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
          sort: 1,
          related: "updateSalesAccount",
          phase: 3,
          action: {
            link: "accounts-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click the orange "Edit" button at the top of the account profile.`,
          sort: 2,
          related: "updateSalesAccount",
          phase: 3,
          action: {
            link: "",
            label: "edit",
            icon: "mdi-pencil",
            color: "orange",
            margin: "",
            iconPosition: "right",
            disabled: true,
          },
        },
        {
          text: `Select your update option to return a list of the related fields for this option. Any fields that have been updated will have an orange background.`,
          sort: 3,
          related: "updateSalesAccount",
          phase: 3,
          action: null,
        },
        {
          text: `Click the "Update" button to submit changes. These will be notated in the Ledger at the bottom of the account profile.`,
          sort: 4,
          related: "updateSalesAccount",
          phase: 3,
          action: {
            link: "",
            label: "update",
            icon: "mdi-check",
            margin: "mr-1",
            color: "orange",
            iconPosition: "right",
            disabled: true,
          },
        },
        // Sales - Contacts
        {
          text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
          sort: 1,
          related: "addSalesContact",
          phase: 3,
          action: {
            link: "accounts-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click blue "+ Contact" within the "Contacts" panel in the account profile.`,
          sort: 2,
          related: "addSalesContact",
          phase: 3,
          action: {
            link: "",
            label: "contact",
            icon: "mdi-plus",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "left",
            disabled: true,
          },
        },
        {
          text: `Input the contact's first name & title (the address will default to the store's shipping address). Contacts require at least an email OR a phone number to submit, but both can be added.`,
          sort: 3,
          related: "addSalesContact",
          phase: 3,
          action: null,
        },
        {
          text: `Contacts are required to submit Sales Orders!`,
          sort: 4,
          related: "addSalesContact",
          phase: 3,
          action: null,
        },
        // Sales - Tasks
        {
          text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
          sort: 1,
          related: "addSalesTasks",
          phase: 3,
          action: {
            link: "accounts-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Navigate to the "Tasks" panel in the account profile.`,
          sort: 2,
          related: "addSalesTasks",
          phase: 3,
          action: null,
        },
        {
          text: `Complete the fields with a yellow background to submit. The task will be assigned to your user by default but can be re-assigned to others. When a new task is submitted, the "Last Task" date on the account will update.`,
          sort: 3,
          related: "addSalesTasks",
          phase: 3,
          action: null,
        },
        {
          text: `Pending tasks will be shown in the "Pending" section of an account profile & on the assigned user's dashboard. The history of all tasks on this account are shown in the "Completed" tab`,
          sort: 4,
          related: "addSalesContact",
          phase: 3,
          action: null,
        },
        // Sales - Create sales order
        {
          text: `Search for an account using the "Accounts Overview" or the "Search Accounts" field at the top of your screen.`,
          sort: 1,
          related: "newSalesOrder",
          phase: 3,
          action: {
            link: "accounts-overview",
            label: "view",
            icon: "mdi-eye",
            color: "#2196F3",
            margin: "mr-1",
            iconPosition: "right",
            disabled: false,
          },
        },
        {
          text: `Click "+ Order" within the "Sales Order" panel in the account profile.`,
          sort: 2,
          related: "newSalesOrder",
          phase: 3,
          action: {
            link: "",
            label: "order",
            icon: "mdi-plus",
            margin: "mr-1",
            color: "#2196F3",
            iconPosition: "left",
            disabled: true,
          },
        },
        {
          text: `Set the order type (Sales/Sample), payment term & begin adding SKUs. Individual SKUs can be added with the blue "Edit" button or "Add Multiple" can be used for multiple SKUs of the same category at once. "Filter and Sort" can be used to filter the menu based on metadata like category/previously purchased...`,
          sort: 3,
          related: "newSalesOrder",
          phase: 3,
          action: null,
        },
        {
          text: `Promo units can be added to an order using the "Promo Mode" toggle. This is only available on SALES orders & the promo % will be calculated within the "Cart" details view before submitting`,
          sort: 4,
          related: "newSalesOrder",
          phase: 3,
          action: null,
        },
        {
          text: `A "Draft" order can be saved after adding 1+ SKU to the cart. After clicking the Cart => "Checkout" button, an option to "Save Draft" is available.`,
          sort: 5,
          related: "newSalesOrder",
          phase: 3,
          action: null,
        },
        {
          text: `Confirm the contact from the account who approved this order. If the contact has not been added to the account, the "+ New Contact" at the bottom of the "Buyer" field will add the contact to the store without having to go back to the Account profile.`,
          sort: 6,
          related: "newSalesOrder",
          phase: 3,
          action: null,
        },
        {
          text: `Most orders will start in a "Submitted" status, but if the account has an existing balance / promo units have been added / etc, the order will start in an "Awaiting Approval" status.`,
          sort: 7,
          related: "newSalesOrder",
          phase: 3,
          action: null,
        },
        // Sales - Sales Orders, Process
        {
          text: `Sales Orders are split into draft/sales (in-progress) & delivered orders. `,
          sort: 1,
          related: "salesOrderProcess",
          phase: 3,
          action: null,
        },
        {
          text: `"Draft" orders have not been submitted yet & the inventory has not been put on reserve. Because mytrace only allows orders to be submitted when the inventory is available, a draft order also functions as a backorder until the QTY is available.`,
          sort: 2,
          related: "salesOrderProcess",
          phase: 3,
          action: null,
        },
        {
          text: `The "Sales Order" view is where all of the in-progress orders are. The statuses follow the direction of the tabs from left => right. Orders start in a "Awaiting Approval"/"Submitted" status & move to "Fulfilled".`,
          sort: 3,
          related: "salesOrderProcess",
          phase: 3,
          action: null,
        },
        {
          text: `Awaiting Approval/Submitted: Initial status of a sales order. Orders will be in "Awaiting Approval" if the account has an existing balance when the order was submitted, promo units were added to the order/etc.`,
          sort: 4,
          related: "salesOrderProcess",
          phase: 3,
          action: null,
        },
        {
          text: `Accepted: When the order has been reviewed & is ready to be fulfilled, the order's status will updated from Submitted => Accepted.`,
          sort: 5,
          related: "salesOrderProcess",
          phase: 3,
          action: null,
        },
        {
          text: `Delivery Confirmation: Once the line item's of the order have been fulfilled, the status is updated to "Delivery Confirmation". The step is meant for confirming a delivery date with the account & any final steps needed for this order to be ready to deliver.`,
          sort: 6,
          related: "salesOrderProcess",
          phase: 3,
          action: null,
        },
        {
          text: `--If line items on the order are updated while it is in the "Delivery Confirmation" status, the order will be moved back to "Accepted"!`,
          sort: 7,
          related: "salesOrderProcess",
          phase: 3,
          action: null,
        },
        {
          text: `Fulfilled: The order is ready to be delivered with a ship date confirmed & all line items fulfilled. Once the order has been delivered, this order will be in the "Delivered Orders" view until the balance is cleared. If the balance is $0 when the order is marked as shipped, it will automatically go into "Complete".`,
          sort: 8,
          related: "salesOrderProcess",
          phase: 3,
          action: null,
        },
        
      ],
    },
    payload: {
      filters: {
        step: 1,
        phase: null,
        guide: null,
      },
    },
  }),
  created() {
    this.setup();
  },
  methods: {
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {
      this.loading.overall = true;
      this.loading.overall = false;
    },
    traverse(action, params) {
      switch (action) {
        case "phase":
          this.payload.filters.phase = params.phase;
          this.payload.filters.step++;
          break;
        case "guide":
          this.payload.filters.guide = params.value;
          this.payload.filters.step++;
          break;
        case "instruction-view":
          if (params.action.link.length > 0) {
            this.$router.push({
              name: params.action.link,
            });
          }
          break;
        case "home":
          this.payload.filters.step = 1;
          this.payload.filters.phase = this.payload.filters.guide = null;
          break;
      }
    },
    /* Misc */
  },
  computed: {
    ...mapGetters([
      // System
      "getAppName",
      "getUser",
      // Phases+Views
      "getPhases",
      "getPhaseViews",
    ]),
    phaseSet() {
      return this.payload.filters.phase ? true : false;
    },
    guideSet() {
      return this.payload.filters.guide ? true : false;
    },
    selectedPhase() {
      return this.phaseSet
        ? this.getPhases.find(
            (option) => option.phase === this.payload.filters.phase
          ).text
        : "-";
    },
    selectedGuide() {
      return this.guideSet
        ? this.response.guides.filter(
            (guide) => guide.value === this.payload.filters.guide
          ).text
        : "-";
    },
    guides() {
      return this.response.guides.filter(
        (guide) => guide.phase === this.payload.filters.phase
      );
    },
    instructions() {
      return this.response.instructions
        .filter(
          (instruction) => instruction.related === this.payload.filters.guide
        )
        .sort((a, b) => (a.sort > b.sort ? 1 : -1));
    },
  },
};
</script>

<style></style>
