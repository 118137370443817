const state = {
    purpose: [
        {
            text: "Header",
            desc: "This part will be shown as a bolded title",
            value: "header",
        },
        {
            text: "Field",
            desc: "This will be shown as a field nested within a 'Header'",
            value: "field",
        },
    ],
    fields: [
        {
            text: "Text field (short)",
            desc: "Text will be shown in a single line text field",
            value: "textfield",
        },
        {
            text: "Textarea (Long)",
            desc: "Text will be displayed in a larger text field that can be resized",
            value: "textarea",
        },
        {
            text: "Link",
            desc: "Link to a URL",
            value: "link",
        },
        // {
        //     text: "Image",
        //     desc: "",
        //     value: "image",
        // },
    ],
    
};

const actions = {};

const mutations = {};

const getters = {
    getQRFieldPurpose(state) {
        return state.purpose;
    },
    getQRFields(state) {
        return state.fields;
    },
    
};

export default {
  state,
  actions,
  mutations,
  getters,
};
